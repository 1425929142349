import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartPlus } from '@fortawesome/free-solid-svg-icons'
import { withRouter, useParams } from 'react-router-dom'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { actionDetailProduct } from '../../store/actions/product'
import { actionAddCart } from '../../store/actions/cart'

import { Loader } from '../../components'
import { 
  formatPrice, 
  formatDiscount, 
  getDiscount, 
  formatPrecentage 
} from '../../helpers'

const Product = ({ 
  history, 
  product, 
  cart,
  authenticate,
  dispatchDetailProduct,
  dispatchAddCart
}) => {
  const { id } = useParams()

  useEffect(() => {
    dispatchDetailProduct(id)
  }, [id, dispatchDetailProduct])

  const handlePageHome = () => {
    history.push('/')
  }

  const handleGoBack = () => {
    history.goBack()
  }

  const handlePageCart = () => {
    history.push('/keranjang')
  }

  const handleAddToCart = async() => {
    const precentage = await formatPrecentage(authenticate.account, product.payload.discount)
    dispatchAddCart(product.payload, getDiscount(product.payload.price, precentage), 1)
  }

  if(!product.payload) return <Loader />

  return (
    <div className="bg-white">
      <div 
        onClick={handlePageCart} 
        className="floating-shop"
      >
        <a className="menu-mobile">
          <FontAwesomeIcon className="fas" icon={faCartPlus} />
        </a>
        {
          (cart.payload.length > 0)
          ? <div className="notif">{cart.payload.length}</div>
          : null
        }
      </div>

      <div className="btn-cover-mobile">
        <a onClick={handleGoBack} className="btn-kembali">Kembali</a>
        <a onClick={handleAddToCart} className="btn-keranjang">Masukan keranjang</a>
      </div>

      <div className="breadcrumb-halaman">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a 
                onClick={handlePageHome} 
                className="clickable" 
              >Beranda</a>
            </li>
            <li className="breadcrumb-item active">Detail produk</li>
          </ol>
        </nav>
      </div>

      <div className="container">
        <div className="cover-detail">
          <img 
            src={process.env.REACT_APP_IMAGE_URL + product.payload.image} 
            alt={product.payload.name} 
          />
          <div className="content-detail">
            <h2 className="judul">{product.payload.name}</h2>
            <div className="harga">
              <p>Harga</p>
              <div className="cover-harga">
                <p className="diskon">{formatPrecentage(authenticate.account, product.payload.discount)}%</p>
                <p className="harga-diskon">{formatPrice(product.payload.price, 'Rp.')}</p>
                <p className="harga-produk">
                  {
                    formatDiscount(
                      product.payload.price, 
                      formatPrecentage(authenticate.account, product.payload.discount), 
                      'Rp.'
                    )
                  }
                </p>
              </div>
            </div>
            <div className="produsen">
              <p className="label-produsen">Produsen</p>
              <p className="nama-produsen">{product.payload.producer.name}</p>
            </div>
            <div className="berat">
              <p className="label-berat">Berat</p>
              <p className="volume-berat">{product.payload.weight}</p>
            </div>
            <div className="btn-cover">
              <a onClick={handleGoBack} className="btn-kembali">Kembali</a>
              <a onClick={handleAddToCart} className="btn-keranjang">Masukan keranjang</a>
            </div>
          </div>
        </div>
        <div className="cover-deskripsi">
          <h2>Deskripsi</h2>
          <p className="text">{product.payload.description}</p>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticate: {
      account: state.auth.authenticate.account
    },
    product: {
      loading: state.product.detailProduct.loading,
      payload: state.product.detailProduct.payload
    },
    cart: {
      payload: state.cart.getCart.payload
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchDetailProduct: (productId) => dispatch(actionDetailProduct(productId)),
    dispatchAddCart: (product, price, quantity) => dispatch(actionAddCart(product, price, quantity))
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Product)