import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Package, Form, ModalSuccess } from './components'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { actionRegister } from '../../store/actions/auth'

const Register = ({ 
  history,
  register, 
  dispatchRegister
}) => {
  const [step, setStep] = useState(1)
  const [type, setType] = useState('')
  const [onState, setOnState] = useState(false)
  const [modalSuccess, setModalSuccess] = useState(false)

  const handleChoosePackage = (choose) => {
    setType(choose)
    setStep(2)
  }

  const handleBackStep = () => {
    setStep(1)
  }

  useEffect(() => {
    if(register.success && onState) {
      setOnState(false)
      setModalSuccess(true)
    }
  }, [register.success, onState])

  const handleRegister = (data) => {
    if(!register.loading) {
      dispatchRegister(data)
      setOnState(true)
    }
  }

  const handleHideModal = () => {
    console.log('HIDE')
  }

  const handlePageHome = () => {
    setModalSuccess(false)
    history.push('/')
  }

  return (
    <div className="container">
      {
        (step === 1)
        ? <Package 
            onChoosePackage={handleChoosePackage}
          />
        : <Form 
            loading={register.loading}
            onBackStep={handleBackStep}
            type={type}
            onRegister={handleRegister}
          />
      }

      <ModalSuccess
        modalShow={modalSuccess}
        onModalHide={handleHideModal}
        onModalClick={handlePageHome}
      />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    register: {
      loading: state.auth.register.loading,
      success: state.auth.register.success
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchRegister: (data) => dispatch(actionRegister(data))
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Register)