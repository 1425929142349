import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faCartPlus, faUser } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { withRouter } from 'react-router-dom'

import { Snackbar } from '../../components'
import validate from 'validate.js'
import PropTypes from 'prop-types'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { actionModal, actionAlert } from '../../store/actions/util'
import { actionSearchProduct } from '../../store/actions/product'
import { actionLogin } from '../../store/actions/auth'

const schema = {
  phone: {
    presence: { 
      allowEmpty: false, 
      message: 'Nomor telepon harus diisi' 
    },
    numericality: { 
      onlyInteger: true, 
      notValid: 'Nomor telepon harus berupa angka' 
    }
  },
  password: {
    presence: { 
      allowEmpty: false, 
      message: 'Kata sandi harus diisi' 
    }
  }
}

const Login = ({ closeModal, onRegister, loginModal, loginAlert }) => {
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  })

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false })

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }))
  }, [formState.values])

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }))
  }

  const hasError = field => formState.errors[field] ? true : false

  const handleLogin = event => {
    event.preventDefault()
    if(formState.isValid) {
      loginModal(formState.values)
      return
    }

    loginAlert(
      true, 
      'error', 
      hasError('phone')
      ? formState.errors.phone[0] 
      : formState.errors.password[0]
    )
  }

  return (
    <div className="login-area">
      <div className="container">
        <div className="cover-login">
          <div className="card-login">
            <div onClick={closeModal} className="close">
              <span></span>
            </div>
            <h2>Masuk</h2>
            <div className="username">
              <label htmlFor="phone">Nomor telepon</label>
              <input 
                type="text" name="phone" id="phone" 
                onChange={handleChange}
                value={formState.values.phone || ''}
                onKeyPress={event => event.key === 'Enter' && handleLogin(event)}
              />
            </div>
            <div className="password">
              <label htmlFor="password">Password</label>
              <input 
                type="password" name="password" id="password" 
                onChange={handleChange}
                value={formState.values.password || ''}
                onKeyPress={event => event.key === 'Enter' && handleLogin(event)}
              />
            </div>
            <a onClick={handleLogin} className="btn-masuk">Masuk</a>
            <div className="belumdaftar">
              <p>Belum punya akun Agen?</p><a onClick={onRegister} className="btn-daftaragen">Daftar Agen</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Guest = ({ 
  history,
  children, 
  contact,
  product,
  modal, 
  cart,
  alert, 
  login, 
  dispatchModal, 
  dispatchAlert, 
  dispatchLogin,
  dispatchSearchProduct
}) => {
  const [showCs, setShowCs] = useState(false)
  const [showLogin, setShowLogin] = useState(false)

  const handleShowLogin = (active) => {
    setShowLogin(active)
    dispatchModal(active)
  }

  useEffect(() => {
    if(login.success) {
      dispatchModal(false)
    }
  }, [login.success])

  const handleLogin = (data) => {
    dispatchLogin(data)
  }

  const handleChangeQuery = (event) => {
    event.persist()

    dispatchSearchProduct(false, event.target.value)
  }

  const handleSearchProduct = () => {
    dispatchSearchProduct(true, product.query)
    history.push('/')
  }

  const handlePageHome = () => {
    history.push('/')
  }

  const handlePageCart = () => {
    history.push('/keranjang')
  }

  const handlePageRegister = () => {
    dispatchModal(false)
    history.push('/daftar-agen')
  }

  const handleActiveClassName = (current, active) =>  {
    if(active) return current + ' active'
    return current
  }

  const handleToogleCs = (active) => {
    setShowCs(active)
  }

  const handleTextCs = () => {
    window.open(`${process.env.REACT_APP_WHATSAPP_URL}${contact.payload.phone}?text=Halo Admin NabawiHerba %0A%0A`, '_blank')
  }

  return (
    <div>
      <Snackbar 
        active={alert.status} 
        severity={alert.severity} 
        message={alert.message}
      />

      <div 
        className="transparan" 
        style={{ display: (modal.active) ? 'block' : 'none' }}
      ></div>

      { 
        (showLogin)
        ? <Login 
            closeModal={() => handleShowLogin(false)}
            loginModal={handleLogin}
            loginAlert={dispatchAlert}
            onRegister={handlePageRegister}
          /> 
        : null 
      }

      <div 
        className={
          handleActiveClassName(
            'hubungikami', 
            showCs
          )
        }
      >
        <div 
          onClick={() => handleToogleCs(false)}
          className="judul"
        >
          <img src={process.env.REACT_APP_IMAGE_URL + 'img/wa.png'} alt="" />
          <p>Hubungi kami</p>
        </div>
        <div className="konten-wa">
          <a onClick={handleTextCs}>
            <img src={process.env.REACT_APP_IMAGE_URL + 'img/cs.png'} alt="" />
            <p>Admin</p>
          </a>
        </div>
        {
          (!showCs)
          ? <a
              onClick={() => handleToogleCs(true)} 
              className="footer"
            >
              <img src={process.env.REACT_APP_IMAGE_URL + 'img/wa.png'} alt="" />
              <p>Hubungi kami</p>
            </a>
          : null
        }
      </div>
      
      <div className="container-besar">
        <nav className="navigation">
          <img 
            onClick={handlePageHome} 
            src={process.env.REACT_APP_IMAGE_URL + 'img/logo-brand.jpeg'}
            className="logo" 
            alt="logo-brand" 
            title="logo-brand" 
          />
          <div className="cover-cari">
            <input 
              type="text" 
              placeholder="cari barang" 
              value={product.query} 
              onChange={handleChangeQuery}
              onKeyPress={event => event.key === 'Enter' && handleSearchProduct()}
            />
            <button id="cari" onClick={handleSearchProduct}>Cari</button>
          </div>
          <div onClick={handlePageCart} className="cover-keranjang">
            <img 
              src={process.env.REACT_APP_IMAGE_URL + 'img/keranjang.svg'}
              className="keranjang-belanja" 
              alt="keranjang-belanja" 
              title="keranjang-belanja" 
            />
            {
              (cart.payload.length > 0)
              ? <span className="badges-keranjang">{cart.payload.length}</span>
              : null
            }
          </div>
          <div className="button-action">
            <a onClick={() => handleShowLogin(true)} className="btn-masuk" id="btn-masuk">Masuk Agen</a>
            <a onClick={handlePageRegister} className="btn-daftar">Daftar Agen</a>
          </div>
        </nav>
      </div>
      <div className="covernav-mobile">
        <nav className="nav-mobile">
          <a 
            onClick={handlePageHome} 
            className={
              handleActiveClassName(
                'menu-mobile', 
                (history.location.pathname === '/')
              )
            }
          >
            <FontAwesomeIcon className="fas" icon={faHome} />
            <p>Beranda</p>
          </a>
          <a 
            onClick={handlePageCart} 
            className={
              handleActiveClassName(
                'menu-mobile', 
                (history.location.pathname === '/keranjang')
              )
            }
          >
            <FontAwesomeIcon className="fas" icon={faCartPlus} />
            <p>Keranjang</p>
            {
              (cart.payload.length > 0)
              ? <div className="notif">{cart.payload.length}</div>
              : null
            }
          </a>
          <a 
            onClick={() => handleToogleCs(!showCs)}
            className="menu-mobile active"
          >
            <FontAwesomeIcon className="fas" icon={faWhatsapp} />
            <p>Chat</p>
          </a>
          <a onClick={() => handleShowLogin(true)} className="menu-mobile">
            <FontAwesomeIcon className="fas" icon={faUser} />
            <p>Masuk Agen</p>
          </a>
        </nav>
      </div>
      <main>
        {children}
      </main>
    </div>
  )
}

Guest.propTypes = {
  children: PropTypes.node
}

const mapStateToProps = (state) => {
  return {
    contact: {
      payload: state.util.contact.payload
    },
    cart: {
      payload: state.cart.getCart.payload
    },
    product: {
      status: state.product.searchProduct.status,
      query: state.product.searchProduct.query
    },
    login: {
      success: state.auth.login.success
    },
    modal: {
      active: state.util.modal.active
    },
    alert: {
      status: state.util.alert.status,
      severity: state.util.alert.severity,
      message: state.util.alert.message
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchModal: (active) => dispatch(actionModal(active)),
    dispatchAlert: (status, severity, message) => dispatch(actionAlert(status, severity, message)),
    dispatchLogin: (data) => dispatch(actionLogin(data)),
    dispatchSearchProduct: (status, query) => dispatch(actionSearchProduct(status, query))
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Guest)