import React from 'react'
import { Switch, Redirect } from 'react-router-dom'

import { 
  RouteLayout as MainRoute, 
  MinimalLayout as MinimalRoute 
} from './components'
import { 
  Auth as AuthLayout, 
  Guest as GuestLayout, 
  Minimal as MinimalLayout 
} from './layouts'

import { connect } from 'react-redux'

import {
  Home as HomeView,
  Category as CategoryView,
  Product as ProductView,
  Cart as CartView,
  Register as RegisterView,
  ChangePassword as ChangePasswordView,
  Policy as PolicyView,
} from './views'

const Routes = ({ authenticate }) => {
  return (
    <Switch>
      <MainRoute
        isAuthenticated={authenticate.status}
        component={HomeView}
        exact
        authLayout={AuthLayout}
        guestLayout={GuestLayout}
        path="/"
      />
      <MainRoute
        isAuthenticated={authenticate.status}
        component={CategoryView}
        exact
        authLayout={AuthLayout}
        guestLayout={GuestLayout}
        path="/kategori/:id"
      />
      <MainRoute
        isAuthenticated={authenticate.status}
        component={ProductView}
        exact
        authLayout={AuthLayout}
        guestLayout={GuestLayout}
        path="/produk/:id"
      />
      <MainRoute
        isAuthenticated={authenticate.status}
        component={CartView}
        exact
        authLayout={AuthLayout}
        guestLayout={GuestLayout}
        path="/keranjang"
      />
      <MinimalRoute
        component={RegisterView}
        exact
        minimalLayout={MinimalLayout}
        path="/daftar-agen"
      />
      <MinimalRoute
        component={ChangePasswordView}
        exact
        minimalLayout={MinimalLayout}
        path="/ganti-password"
      />
      <MainRoute
        isAuthenticated={authenticate.status}
        component={HomeView}
        exact
        authLayout={AuthLayout}
        guestLayout={GuestLayout}
        path="/auth"
      />
      <MinimalRoute
        component={PolicyView}
        exact
        minimalLayout={MinimalLayout}
        path="/policy"
      />
      <Redirect to="/not-found" />
    </Switch>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticate: {
      status: state.auth.authenticate.status
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Routes)