import React, { useState, useEffect } from 'react'

import { withRouter } from 'react-router-dom'

import { compose } from 'redux'
import { connect } from 'react-redux'
import {
  actionUpdateCart,
  actionRemoveCart,
  actionRemoveAllCart,
  actionGetVoucher,
} from '../../store/actions/cart'
import { actionSaveTransaction } from '../../store/actions/transaction'

import { formatPrice } from '../../helpers'

const Cart = ({
  history,
  authenticate,
  contact,
  cart,
  voucher,
  transaction,
  dispatchUpdateCart,
  dispatchRemoveCart,
  dispatchRemoveAllCart,
  dispatchSaveTransaction,
  dispatchGetVoucher
}) => {
  const [total, setTotal] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [message, setMessage] = useState('')
  const [phone, setPhone] = useState('')
  const [code, setCode] = useState('')
  const [onState, setOnState] = useState(false)

  useEffect(() => {
    setTotal(cart.payload.reduce((current, item) => current + (item.price * item.quantity), 0))
  }, [cart.payload])

  useEffect(() => {
    if (voucher.payload) {
      setDiscount(parseFloat(voucher.payload?.value || 0))
      return
    }

    setDiscount(0)
  }, [voucher.payload])

  useEffect(() => {
    if (voucher.payload && total < parseFloat(voucher.payload?.min_purchase)) {
      dispatchGetVoucher(code, total)
    }
  }, [total, voucher.payload])

  const handlePageHome = () => {
    history.push('/')
  }

  const handleChangeCode = (event) => {
    setCode(event.target.value)
  }

  const handleSearchVoucher = () => {
    if(!voucher.loading) {
      dispatchGetVoucher(code, total)
    }
  }

  const handleUpdateQuantity = (product, price, quantity) => {
    if(quantity > 0) {
      dispatchUpdateCart(product, price, quantity)
    }
  }

  const handleRemoveCart = (productId) => {
    dispatchRemoveCart(productId)
  }

  useEffect(() => {
    if(transaction.payload && onState) {
      setOnState(false)
      dispatchRemoveAllCart()
      const text = `Kode ${transaction.payload} %0A%0A${message}`
      window.open(`${process.env.REACT_APP_WHATSAPP_URL}${phone}?text=${text}`, '_blank')
    }
  }, [transaction.payload, dispatchRemoveAllCart, onState])

  const handleCheckout = () => {
    if(!transaction.loading && !voucher.loading) {
      const data = {
        total: 0,
        user_id: (authenticate.account)? authenticate.account.id : null,
        orders: [],
        voucher_id: (voucher.payload) ? voucher.payload?.id : null,
      }

      let text = ''
      for(const item of cart.payload) {
        data.total += (Number(item.price) * Number(item.quantity))
        data.orders.push({
          quantity: Number(item.quantity),
          price: Number(item.price),
          product_id: item.product.id
        })
        text += `- ${item.product.name} @${formatPrice(item.price, 'Rp.')} x ${item.quantity}%0A`
      }

      if (authenticate.account) {
        text += `%0ATotal Belanja ${formatPrice(total, 'Rp.')}`
        text += `%0APotongan Harga ${formatPrice(discount, '- Rp.')}`
      }
      text += `%0ATotal Bayar ${formatPrice((total - discount), 'Rp.')}`

      setMessage(text)
      setPhone(contact.payload.phone)
      setOnState(true)
      dispatchSaveTransaction(data)
      setDiscount(0)
      setCode('')
    }
  }

  return (
    <>
      <div className="breadcrumb-halaman">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a 
                onClick={handlePageHome} 
                className="clickable" 
              >Beranda</a>
            </li>
            <li className="breadcrumb-item active">Keranjang belanja</li>
          </ol>
        </nav>
      </div>
      <div className="totalbayar-mobile">
        <div className="voucher-mobile">
          <label htmlFor="voucher">Kode Voucher</label>
          <div className="form-voucher">
            <input 
              type="text"
              name="voucher"
              id="voucher"
              value={code}
              onChange={handleChangeCode}
            />

            <div
              className="btn-voucher"
              onClick={handleSearchVoucher}
            >
              Gunakan
            </div>
          </div>
        </div>
        <div className="detail">
          <div className="isi-total">
            <p className="totalharga">Total Bayar</p>
            <p className="total">{formatPrice((total - discount), 'Rp.')}</p>
            {
              (discount > 0) && (
                <p className="totalpotongan">{formatPrice(total, 'Rp.')}</p>
              )
            }
          </div>
          {
            (cart.payload.length > 0)
            ? <div onClick={handleCheckout} className="btn-bayar">
                <a className="btn-bayar">
                  {
                    (transaction.loading || voucher.loading)
                    ? 'Menunggu..'
                    : `Beli produk (${cart.payload.length})`
                  }
                </a>
              </div>
            : null
          }
        </div>
      </div>
      <div className="container">
        <div className="cover-keranjang-all">
          <div className="cover-isi">
            <div className="cover-card-produk">
              <h2 className="judul">Keranjang belanja</h2>
              {
                (cart.payload.length > 0)
                ? <>
                    {
                      cart.payload.map((item, index) => {
                        return (
                          <div key={index} className="card-produk">
                            <img 
                              src={process.env.REACT_APP_IMAGE_URL + item.product.image}
                              alt={item.product.name} 
                            />
                            <div className="isi-konten">
                              <p className="judul">{item.product.name}</p>
                              <p className="harga">{formatPrice(item.price, 'Rp.')}</p>
                            </div>
                            <div className="btn-card-prod">
                              <div className="nice-number">
                                <button 
                                  type="button"
                                  onClick={() => handleUpdateQuantity(item.product, item.price, (item.quantity-1))}
                                >-</button>
                                <input type="number" value={item.quantity} readOnly />
                                <button 
                                  type="button"
                                  onClick={() => handleUpdateQuantity(item.product, item.price, (item.quantity+1))}
                                >+</button>
                              </div>
                              <a className="pointer" onClick={() => handleRemoveCart(item.product.id)}>Hapus</a>
                            </div>
                          </div>
                        )
                      })
                    }
                  </>
                : <div className="btn-bayar">
                    <a onClick={handlePageHome} className="btn-bayar">Belanja Sekarang!</a>
                  </div>
              }
            </div>
            <div className="totalbayar">
              <h2 className="ringkasan"> Ringkasan belanja</h2>
              {
                authenticate.account && (
                  <div className="voucher">
                    <label htmlFor="voucher">Kode Voucher</label>
                    <div className="form-voucher">
                      <input 
                        type="text"
                        name="voucher"
                        id="voucher"
                        value={code}
                        onChange={handleChangeCode}
                      />

                      <div
                        className="btn-voucher"
                        onClick={handleSearchVoucher}
                      >
                        Gunakan
                      </div>
                    </div>
                  </div>
                )
              }
              
              <div className="isi-total">
                {
                  authenticate.account && (
                    <>
                      <p className="totalharga">Total Belanja</p>
                      <p className="total">{formatPrice(total, 'Rp.')}</p>
                      <p className="totalharga">Potongan Harga</p>
                      <p className="totalpotongan">{formatPrice(discount, '- Rp.')}</p>
                    </>
                  )
                }
                <p className="totalharga">Total Bayar</p>
                <p className="total">{formatPrice((total - discount), 'Rp.')}</p>
              </div>

              {
                (cart.payload.length > 0)
                ? <div onClick={handleCheckout} className="btn-bayar">
                    <a className="btn-bayar">
                      {
                        (transaction.loading || voucher.loading)
                        ? 'Menunggu..'
                        : `Beli produk (${cart.payload.length})`
                      }
                    </a>
                  </div>
                : null
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticate: {
      account: state.auth.authenticate.account
    },
    voucher: {
      loading: state.cart.getVoucher.loading,
      payload: state.cart.getVoucher.payload
    },
    contact: {
      payload: state.util.contact.payload
    },
    cart: {
      payload: state.cart.getCart.payload
    },
    transaction: {
      loading: state.transaction.saveTransaction.loading,
      payload: state.transaction.saveTransaction.payload
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchUpdateCart: (product, price, quantity) => dispatch(actionUpdateCart(product, price, quantity)),
    dispatchRemoveCart: (productId) => dispatch(actionRemoveCart(productId)),
    dispatchRemoveAllCart: () => dispatch(actionRemoveAllCart()),
    dispatchSaveTransaction: (data) => dispatch(actionSaveTransaction(data)),
    dispatchGetVoucher: (name, total) => dispatch(actionGetVoucher(name, total)),
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Cart)