import React, { useEffect, useState, useRef } from 'react'
import { useMediaPredicate } from 'react-media-hook'
import { Dropdown } from 'react-bootstrap'

import { Carousel } from '../../components'
import { withRouter } from 'react-router-dom'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { actionListBanner } from '../../store/actions/banner'
import { actionListCategory } from '../../store/actions/category'
import { actionListProducer } from '../../store/actions/producer'
import { actionListProduct, actionSearchProduct } from '../../store/actions/product'
import { actionModal } from '../../store/actions/util'
import { formatPrice, formatDiscount, formatPrecentage } from '../../helpers'

const ModalCategory = ({ categories, closeModal, showCategory }) => {
  return (
    <div className="cover-kategori-all">
      <div className="judul">
        <h2>Semua kategori</h2>
      </div>

      <div className="content-kategori-all">
        {
          categories.map((category, index) => {
            return (
              <div 
                key={index} 
                className="card-kategori"
                onClick={() => showCategory(category.id)}
              >
                <img src={ process.env.REACT_APP_IMAGE_URL + category.image } alt="" />
                <p className="judul-kategori">{category.name}</p>
              </div>
            )
          })
        }
      </div>

      <div className="btn-kategori-modal">
        <a onClick={() => closeModal(false)} id="tutup-modal">Tutup</a>
      </div>
    </div>
  )
}

const Home = ({ 
  history,
  authenticate,
  banner, 
  category, 
  producer, 
  product, 
  dispatchListBanner, 
  dispatchListCategory, 
  dispatchModal, 
  dispatchListProducer,
  dispatchListProduct,
  dispatchSearchProduct
}) => {
  const mobile = useMediaPredicate('(max-width: 600px)')
  const productRef = useRef(null)
  const limit = 12
  const [showCategory, setShowCategory] = useState(false)
  const [onState, setOnState] = useState(true)
  const [updateState, setUpdateState] = useState(false)
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [producerId, setProducerId] = useState('')
  const [producerName, setProducerName] = useState('Semua Produsen')
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([])

  useEffect(() => {
    dispatchListBanner()
    dispatchListCategory()
    dispatchListProducer()
  }, [dispatchListBanner, dispatchListCategory, dispatchListProducer])

  useEffect(() => {
    if(category.payload.length > 0) {
      setCategories((mobile)? category.payloadMobile : category.payload)
    }
  }, [category.payload, category.payloadMobile, mobile])

  useEffect(() => {
    if(onState) {
      dispatchListProduct(page, limit, product.query, producerId, '')
      setUpdateState(true)
    }
  }, [dispatchListProduct, onState, page, limit, producerId])

  useEffect(() => {
    if(product.status) {
      setProducts([])
      setPage(1)
      setOnState(true)
      setTimeout(() => {
        window.scrollTo({ 
          top: productRef.current.offsetTop,
          behavior: 'smooth', 
        })
        dispatchSearchProduct(false, product.query)
      }, 500)
    }
  }, [product.status])

  useEffect(() => {
    if(!product.loading && updateState) {
      setOnState(false)
      setUpdateState(false)
      setProducts(products.concat(product.payload))
      setPage(product.page)
      setPages(product.pages)
    }
  }, [product.payload, product.loading, product.page, product.pages, updateState])

  const handleShowCategoryAll = (active) => {
    dispatchModal(active)
    setShowCategory(active)
  }

  const handleSelectProducer = (item) => {
    setProducts([])
    setProducerId(item.id)
    setProducerName(item.name)
    setPage(1)
    setOnState(true)
  }

  const handleLoadMore = () => {
    setPage(page+1)
    setOnState(true)
  }

  const handlePageCategory = (categoryId) => {
    dispatchModal(false)
    history.push(`/kategori/${categoryId}`)
  }

  const handlePageProduct = (productId) => {
    history.push(`/produk/${productId}`)
  }

  return (
    <>
      <div className="iklan-mobile">
        <Carousel banners={banner.payload} />
      </div>

      <div className="container">
        <Carousel banners={banner.payload} />
        
        {
          (authenticate.account)
          ? <div className="cover-welcome">
              Hi "<span className="name">{authenticate.account.name}</span>" selamat belanja
            </div>
          : null
        }

        <div className="cover-kategori">
          <div className="judul">
            <img 
              src={process.env.REACT_APP_IMAGE_URL + 'img/judul-cat.svg'}
              alt="aksen-judul" 
              title="aksen-judul" 
            />
            <a 
              onClick={() => handleShowCategoryAll(true)} 
              className="btn-viewall" 
              id="btn-viewall"
            >Lihat semua</a>
          </div>

          <div className="content-kategori">
            {
              categories.map((category, index) => {
                return (
                  <div 
                    key={index} 
                    className="card-kategori"
                    onClick={() => handlePageCategory(category.id)}
                  >
                    <img src={ process.env.REACT_APP_IMAGE_URL + category.image } alt="" />
                    <p className="judul-kategori">{category.name}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
        
        {
          (showCategory)
          ? <ModalCategory
              categories={category.payloadAll} 
              closeModal={handleShowCategoryAll}
              showCategory={handlePageCategory}
            />
          : null
        }

        <div ref={productRef} className="cover-judulkatalog">
          <div className="judul">
            <img 
              src={process.env.REACT_APP_IMAGE_URL + 'img/judul-katalog.svg'}
              alt="aksen-judul" 
              title="aksen-judul" 
            />
          </div>
          <Dropdown className="dropdowncat">
            <Dropdown.Toggle className="btn-secondary btn-cat">
              <span className="name">{producerName}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="dropdown-menu-right">
              {
                producer.payload.map((item, index) => {
                  return (
                    <Dropdown.Item 
                      key={index}
                      eventKey={item.id}
                      onClick={() => handleSelectProducer(item)}
                    >
                      {item.name}
                    </Dropdown.Item>
                  )
                })
              }
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="cover-katalog">
          {
            products.map((item, index) => {
              return (
                <div 
                  key={index} 
                  className="card-catalog" 
                  onClick={() => handlePageProduct(item.id)}
                >
                  <img 
                    src={process.env.REACT_APP_IMAGE_URL + item.image} 
                    alt={item.name} 
                    title="foto-catalog" 
                  />
                  <p className="judul-produk">{item.name}</p>
                  <p className="produsen-produk">{item.producer.name}</p>
                  <p className="diskon">{formatPrecentage(authenticate.account, item.discount)}%</p>
                  <p className="harga-diskon">{formatPrice(item.price, 'Rp.')}</p>
                  <p className="harga-produk">
                    {
                      formatDiscount(
                        item.price, 
                        formatPrecentage(authenticate.account, item.discount), 
                        'Rp.'
                      )
                    }
                  </p>
                </div>
              )
            })
          }
        </div>
        <div className="cover-more">
          {
            (page < pages)
            ? <a onClick={handleLoadMore} className="btn-more">Muat Lebih Banyak</a>
            : <div className="text-more">Sudah Ditampilkan Semua</div>
          }
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticate: {
      account: state.auth.authenticate.account
    },
    banner: {
      loading: state.banner.listBanner.loading,
      payload: state.banner.listBanner.payload
    },
    category: {
      loading: state.category.listCategory.loading,
      payload: state.category.listCategory.payload,
      payloadMobile: state.category.listCategory.payloadMobile,
      payloadAll: state.category.listCategory.payloadAll
    },
    producer: {
      loading: state.producer.listProducer.loading,
      payload: state.producer.listProducer.payload
    },
    product: {
      status: state.product.searchProduct.status,
      query: state.product.searchProduct.query,
      loading: state.product.listProduct.loading,
      page: state.product.listProduct.page,
      pages: state.product.listProduct.pages,
      payload: state.product.listProduct.payload
    }
  }
}

const mapDispatchToProps = (dispatch) => { 
  return {
    dispatchListBanner: () => dispatch(actionListBanner()),
    dispatchListCategory: () => dispatch(actionListCategory()),
    dispatchModal: (active) => dispatch(actionModal(active)),
    dispatchListProducer: () => dispatch(actionListProducer()),
    dispatchListProduct: (page, limit, name, producerId, categoryId) => dispatch(actionListProduct(page, limit, name, producerId, categoryId)),
    dispatchSearchProduct: (status, query) => dispatch(actionSearchProduct(status, query))
  }
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Home)