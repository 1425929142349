import React from 'react'

const Policy = () => (
  <div className="policy">
    <h1>KEBIJAKAN PRIVASI NABAWI HERBA</h1>

    <p>Nabawi Herba adalah aplikasi keagenan dan pembelian Herbal Nabawi Herba, dengan diskon harga yang akan tampil sesuai jenis grade Agen tersebut.</p>
    <p>Pemesanan melalui aplikasi Nabawi Herba, akan diantarkan baik secara langsung maupun via jasa pengiriman ke alamat agen atau konsumen tersebut secara langsung.</p>
    <p>Dengan menggunakan aplikasi Nabawi Herba, anda telah mempercayakan informasi Anda kepada kami terkait data pribadi (Nama, Alamat, Email, nomer Hp) serta pemesanan herbal yang anda lakukan.</p>
    <p>Kebijakan Privasi ini bertujuan untuk membantu Anda memahami informasi yang kami kumpulkan, alasan kami mengumpulkannya, dan cara untuk memperbarui serta mengelola informasi Anda.</p>

    <ol>
      <li>
        <span>Informasi apa yang kami kumpulkan?</span>Data diri pribadi pelanggan, terkait nama, alamat pengiriman, nomor telepon, dan alamat email.
      </li>
      <li>
        <span>Dari mana kami mengumpulkan informasi tersebut?</span> Data kami kumpulkan melalui proses registrasi di aplikasi android Nabawi Herba dan proses pendaftaran oleh Agen Nabawi Herba.
      </li>
      <li>
        <span>Untuk apa informasi tersebut kami gunakan?</span> Kami menggunakan informasi tersebut untuk proses pemesanan dan pengiriman ke alamat anda.
      </li>
      <li>
        <span>Bagaimana pelanggan dapat mengontrol informasi pribadinya?</span> Pelanggan dapat mengubah data pribadinya melalui halaman pengaturan bagian edit profil pada aplikasi android Nabawi Herba.
      </li>
      <li>
        Menjaga keamanan informasi Anda Kami tidak akan menggunakan data pribadi anda untuk kepentingan selain transaksi pemesanan Nabawi Herba.
      </li>
    </ol>
  </div>
)

export default Policy

