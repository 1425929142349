import React from 'react'
import PropTypes from 'prop-types'

const Package = ({ onChoosePackage }) => {
  return (
    <div className="cover-pagedaftar">
      <div className="cover-headerdaftaragen">
        <div className="judul">
          <h2>Paket agen</h2>
          <p>Pilih paket agen yang anda inginkan</p>
        </div>
        <div className="step">
          <p>Step 1</p>
        </div>
      </div>

      <div className="cover-daftaragen">
        <div className="card-daftaragen" id="agn1">
          <p className="judul-agen">Agen grade C</p>
          <p className="diskon">Diskon 35%</p>
          <p className="minimal">Minimal Belanja Awal Nett</p>
          <p className="harga">Rp. 350,000</p>
          <p className="keterangan">Bisa Campur</p>
          <button 
            onClick={() => onChoosePackage('grade_c')} 
            className="btn-pilih" 
            id="agn1"
          >pilih paket</button>
        </div>
        <div className="card-daftaragen" id="agn2">
          <p className="judul-agen">Agen grade B</p>
          <p className="diskon">Diskon 40%</p>
          <p className="minimal">Minimal Belanja Awal Nett</p>
          <p className="harga">Rp. 500,000</p>
          <p className="keterangan">Bisa Campur</p>
          <button 
            onClick={() => onChoosePackage('grade_b')} 
            className="btn-pilih" 
            id="agn2"
          >pilih paket</button>
        </div>
        <div className="card-daftaragen" id="agn3">
          <p className="judul-agen">Agen grade B+</p>
          <p className="diskon">Diskon 42.5%</p>
          <p className="minimal">Minimal Belanja Awal Nett</p>
          <p className="harga">Rp. 1,000,000</p>
          <p className="keterangan">Bisa Campur</p>
          <button 
            onClick={() => onChoosePackage('grade_bplus')} 
            className="btn-pilih" 
            id="agn3"
          >pilih paket</button>
        </div>
        <div className="card-daftaragen" id="agn4">
          <p className="judul-agen">Agen grade A</p>
          <p className="diskon">Diskon 45%</p>
          <p className="minimal">Minimal Belanja Awal Nett</p>
          <p className="harga">Rp. 2,000,000</p>
          <p className="keterangan">Bisa Campur</p>
          <button 
            onClick={() => onChoosePackage('grade_a')}
            className="btn-pilih" 
            id="agn4"
          >pilih paket</button>
        </div>
        <div className="card-daftaragen" id="agn5">
          <p className="judul-agen">Agen grade A+</p>
          <p className="diskon">Diskon 45% - 50%</p>
          <p className="minimal">Minimal Belanja Awal Nett</p>
          <p className="harga">Rp. 3,000,000</p>
          <p className="keterangan">Bisa Campur</p>
          <button 
            onClick={() => onChoosePackage('grade_aplus')}
            className="btn-pilih" 
            id="agn5"
          >pilih paket</button>
        </div>
      </div>
    </div>
  )
}

Package.propTypes = {
  onChoosePackage: PropTypes.func.isRequired
}

export default Package